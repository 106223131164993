export const albums_data = {
   "canada-usa-2024": {
      "name": "Canada-USA_2024",
      "photos": [
         {
            "faces": [],
            "height": 2160,
            "name": "20240724_122222.jpg",
            "slug": "20240724-122222-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x900_20240724-122222-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x576_20240724-122222-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x900_20240724-122222-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x281_20240724-122222-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x450_20240724-122222-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x281_20240724-122222-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2160,
            "name": "20240724_122653.jpg",
            "slug": "20240724-122653-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x900_20240724-122653-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x576_20240724-122653-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x900_20240724-122653-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x281_20240724-122653-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x450_20240724-122653-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x281_20240724-122653-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2160,
            "name": "20240724_130240.jpg",
            "slug": "20240724-130240-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x900_20240724-130240-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x576_20240724-130240-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x900_20240724-130240-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x281_20240724-130240-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x450_20240724-130240-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x281_20240724-130240-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2688,
            "name": "20240724_130448_rot.jpg",
            "slug": "20240724-130448-rot-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1512x2688_20240724-130448-rot-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x1820_20240724-130448-rot-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1512x2688_20240724-130448-rot-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x888_20240724-130448-rot-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x1422_20240724-130448-rot-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x888_20240724-130448-rot-jpg.jpg",
            "width": 1512
         },
         {
            "faces": [],
            "height": 3840,
            "name": "20240805_154031_rot.jpg",
            "slug": "20240805-154031-rot-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x2844_20240805-154031-rot-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x1820_20240805-154031-rot-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x2844_20240805-154031-rot-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/499x888_20240805-154031-rot-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x1422_20240805-154031-rot-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/499x888_20240805-154031-rot-jpg.jpg",
            "width": 2160
         },
         {
            "faces": [],
            "height": 3840,
            "name": "20240805_212527_rot.jpg",
            "slug": "20240805-212527-rot-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x2844_20240805-212527-rot-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x1820_20240805-212527-rot-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x2844_20240805-212527-rot-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/499x888_20240805-212527-rot-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x1422_20240805-212527-rot-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/499x888_20240805-212527-rot-jpg.jpg",
            "width": 2160
         },
         {
            "faces": [],
            "height": 3840,
            "name": "20240807_131709_rot.jpg",
            "slug": "20240807-131709-rot-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x2844_20240807-131709-rot-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x1820_20240807-131709-rot-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x2844_20240807-131709-rot-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/499x888_20240807-131709-rot-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x1422_20240807-131709-rot-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/499x888_20240807-131709-rot-jpg.jpg",
            "width": 2160
         },
         {
            "faces": [],
            "height": 3840,
            "name": "20240808_154152_rot.jpg",
            "slug": "20240808-154152-rot-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x2844_20240808-154152-rot-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x1820_20240808-154152-rot-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x2844_20240808-154152-rot-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/499x888_20240808-154152-rot-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x1422_20240808-154152-rot-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/499x888_20240808-154152-rot-jpg.jpg",
            "width": 2160
         },
         {
            "faces": [],
            "height": 1512,
            "name": "20240810_155916.jpg",
            "slug": "20240810-155916-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x900_20240810-155916-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x576_20240810-155916-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x900_20240810-155916-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/499x281_20240810-155916-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x450_20240810-155916-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/499x281_20240810-155916-jpg.jpg",
            "width": 2688
         },
         {
            "faces": [],
            "height": 4096,
            "name": "IMG_20240808_040825964_rot.jpg",
            "slug": "img-20240808-040825964-rot-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x2133_img-20240808-040825964-rot-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x1365_img-20240808-040825964-rot-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x2133_img-20240808-040825964-rot-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x666_img-20240808-040825964-rot-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x1066_img-20240808-040825964-rot-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x666_img-20240808-040825964-rot-jpg.jpg",
            "width": 3072
         },
         {
            "faces": [],
            "height": 4096,
            "name": "IMG_20240808_040835637_rot.jpg",
            "slug": "img-20240808-040835637-rot-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x2133_img-20240808-040835637-rot-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x1365_img-20240808-040835637-rot-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x2133_img-20240808-040835637-rot-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x666_img-20240808-040835637-rot-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x1066_img-20240808-040835637-rot-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x666_img-20240808-040835637-rot-jpg.jpg",
            "width": 3072
         },
         {
            "faces": [],
            "height": 3072,
            "name": "IMG_20240808_204428193.jpg",
            "slug": "img-20240808-204428193-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1200_img-20240808-204428193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x768_img-20240808-204428193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1200_img-20240808-204428193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x375_img-20240808-204428193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x600_img-20240808-204428193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x375_img-20240808-204428193-jpg.jpg",
            "width": 4096
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4649.JPG",
            "slug": "img-4649-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4649-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4649-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4649-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4649-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4649-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4649-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4652.JPG",
            "slug": "img-4652-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4652-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4652-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4652-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4652-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4652-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4652-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4653.JPG",
            "slug": "img-4653-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4653-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4653-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4653-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4653-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4653-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4653-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4660.JPG",
            "slug": "img-4660-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4660-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4660-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4660-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4660-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4660-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4660-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4662.JPG",
            "slug": "img-4662-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4662-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4662-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4662-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4662-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4662-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4662-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4666.JPG",
            "slug": "img-4666-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4666-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4666-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4666-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4666-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4666-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4666-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4669.JPG",
            "slug": "img-4669-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4669-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4669-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4669-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4669-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4669-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4669-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4671.JPG",
            "slug": "img-4671-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4671-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4671-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4671-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4671-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4671-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4671-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4673.JPG",
            "slug": "img-4673-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4673-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4673-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4673-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4673-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4673-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4673-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4680.JPG",
            "slug": "img-4680-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4680-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4680-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4680-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4680-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4680-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4680-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4685.JPG",
            "slug": "img-4685-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4685-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4685-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4685-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4685-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4685-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4685-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4686.JPG",
            "slug": "img-4686-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4686-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4686-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4686-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4686-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4686-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4686-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4689.JPG",
            "slug": "img-4689-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4689-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4689-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4689-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4689-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4689-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4689-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4699.JPG",
            "slug": "img-4699-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4699-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4699-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4699-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4699-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4699-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4699-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4707.JPG",
            "slug": "img-4707-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4707-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4707-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4707-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4707-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4707-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4707-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4709.JPG",
            "slug": "img-4709-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4709-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4709-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4709-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4709-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4709-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4709-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4710.JPG",
            "slug": "img-4710-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4710-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4710-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4710-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4710-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4710-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4710-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4712.JPG",
            "slug": "img-4712-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4712-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4712-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4712-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4712-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4712-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4712-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4715.JPG",
            "slug": "img-4715-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4715-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4715-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4715-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4715-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4715-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4715-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4717.JPG",
            "slug": "img-4717-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4717-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4717-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4717-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4717-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4717-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4717-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4720.JPG",
            "slug": "img-4720-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4720-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4720-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4720-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4720-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4720-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4720-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4723.JPG",
            "slug": "img-4723-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4723-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4723-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4723-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4723-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4723-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4723-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4724.JPG",
            "slug": "img-4724-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4724-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4724-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4724-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4724-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4724-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4724-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4728.JPG",
            "slug": "img-4728-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4728-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4728-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4728-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4728-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4728-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4728-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4731.JPG",
            "slug": "img-4731-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4731-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4731-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4731-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4731-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4731-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4731-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4734.JPG",
            "slug": "img-4734-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4734-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4734-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4734-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4734-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4734-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4734-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4735.JPG",
            "slug": "img-4735-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4735-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4735-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4735-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4735-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4735-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4735-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4748.JPG",
            "slug": "img-4748-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4748-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4748-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4748-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4748-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4748-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4748-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4770.JPG",
            "slug": "img-4770-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4770-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4770-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4770-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4770-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4770-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4770-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4780.JPG",
            "slug": "img-4780-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4780-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4780-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4780-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4780-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4780-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4780-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4781.JPG",
            "slug": "img-4781-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4781-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4781-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4781-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4781-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4781-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4781-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4787.JPG",
            "slug": "img-4787-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4787-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4787-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4787-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4787-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4787-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4787-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4803.JPG",
            "slug": "img-4803-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4803-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4803-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4803-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4803-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4803-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4803-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 3840,
            "name": "IMG_4818.JPG",
            "slug": "img-4818-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x2400_img-4818-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x1536_img-4818-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x2400_img-4818-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x750_img-4818-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x1200_img-4818-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x750_img-4818-jpg.jpg",
            "width": 2560
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4831.JPG",
            "slug": "img-4831-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4831-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4831-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4831-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4831-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4831-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4831-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4834.JPG",
            "slug": "img-4834-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4834-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4834-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4834-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4834-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4834-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4834-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4844.JPG",
            "slug": "img-4844-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4844-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4844-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4844-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4844-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4844-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4844-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4849.JPG",
            "slug": "img-4849-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4849-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4849-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4849-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4849-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4849-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4849-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4853.JPG",
            "slug": "img-4853-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4853-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4853-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4853-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4853-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4853-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4853-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4864.JPG",
            "slug": "img-4864-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4864-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4864-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4864-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4864-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4864-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4864-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4867.JPG",
            "slug": "img-4867-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4867-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4867-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4867-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4867-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4867-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4867-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4871.JPG",
            "slug": "img-4871-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4871-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4871-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4871-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4871-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4871-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4871-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4877.JPG",
            "slug": "img-4877-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4877-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4877-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4877-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4877-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4877-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4877-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4888.JPG",
            "slug": "img-4888-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4888-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4888-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4888-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4888-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4888-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4888-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4891.JPG",
            "slug": "img-4891-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4891-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4891-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4891-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4891-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4891-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4891-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4893.JPG",
            "slug": "img-4893-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4893-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4893-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4893-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4893-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4893-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4893-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4895.JPG",
            "slug": "img-4895-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4895-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4895-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4895-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4895-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4895-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4895-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4896.JPG",
            "slug": "img-4896-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4896-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4896-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4896-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4896-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4896-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4896-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4901.JPG",
            "slug": "img-4901-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4901-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4901-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4901-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4901-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4901-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4901-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4907.JPG",
            "slug": "img-4907-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4907-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4907-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4907-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4907-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4907-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4907-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4909.JPG",
            "slug": "img-4909-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4909-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4909-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4909-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4909-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4909-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4909-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4910.JPG",
            "slug": "img-4910-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4910-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4910-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4910-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4910-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4910-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4910-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4912.JPG",
            "slug": "img-4912-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4912-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4912-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4912-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4912-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4912-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4912-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4914.JPG",
            "slug": "img-4914-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4914-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4914-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4914-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4914-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4914-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4914-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4943.JPG",
            "slug": "img-4943-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4943-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4943-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4943-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4943-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4943-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4943-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4946.JPG",
            "slug": "img-4946-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4946-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4946-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4946-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4946-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4946-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4946-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2560,
            "name": "IMG_4947.JPG",
            "slug": "img-4947-jpg",
            "src": "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4947-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/canada-usa-2024/1024x682_img-4947-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/canada-usa-2024/1600x1066_img-4947-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/canada-usa-2024/500x333_img-4947-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/canada-usa-2024/800x533_img-4947-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/canada-usa-2024/500x333_img-4947-jpg.jpg",
            "width": 3840
         }
      ],
      "slug": "canada-usa-2024",
      "src": "/static/_gallery/albums/canada-usa-2024/500x281_20240724-122222-jpg.jpg"
   }
};